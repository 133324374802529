import { c as getDesignTokenValue } from './ComponentUtils-383ff75c.js';
import { B as isNumber } from './element-d46853d9.js';

const inBetween = (value, min, max) => min <= value && value <= max;
const getBreakpoints = () => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
  return ({
    xs: {
      min: (_a = parseInt(getDesignTokenValue('--one-media-breakpoint-xs-min'), 10)) !== null && _a !== void 0 ? _a : 0,
      max: (_b = parseInt(getDesignTokenValue('--one-media-breakpoint-xs-max'), 10)) !== null && _b !== void 0 ? _b : 0
    },
    sm: {
      min: (_c = parseInt(getDesignTokenValue('--one-media-breakpoint-s-min'), 10)) !== null && _c !== void 0 ? _c : 0,
      max: (_d = parseInt(getDesignTokenValue('--one-media-breakpoint-s-max'), 10)) !== null && _d !== void 0 ? _d : 0
    },
    md: {
      min: (_e = parseInt(getDesignTokenValue('--one-media-breakpoint-m-min'), 10)) !== null && _e !== void 0 ? _e : 0,
      max: (_f = parseInt(getDesignTokenValue('--one-media-breakpoint-m-max'), 10)) !== null && _f !== void 0 ? _f : 0
    },
    lg: {
      min: (_g = parseInt(getDesignTokenValue('--one-media-breakpoint-l-min'), 10)) !== null && _g !== void 0 ? _g : 0,
      max: (_h = parseInt(getDesignTokenValue('--one-media-breakpoint-l-max'), 10)) !== null && _h !== void 0 ? _h : 0
    },
    xl: {
      min: (_j = parseInt(getDesignTokenValue('--one-media-breakpoint-xl-min'), 10)) !== null && _j !== void 0 ? _j : 0,
      max: (_k = parseInt(getDesignTokenValue('--one-media-breakpoint-xl-max'), 10)) !== null && _k !== void 0 ? _k : 0
    }
  });
};
const getScreenSize = (width) => {
  const breakpoints = getBreakpoints();
  if (width < breakpoints.sm.min) {
    return 'xs';
  }
  else if (inBetween(width, breakpoints.sm.min, breakpoints.sm.max)) {
    return 'sm';
  }
  else if (inBetween(width, breakpoints.md.min, breakpoints.md.max)) {
    return 'md';
  }
  else if (inBetween(width, breakpoints.lg.min, breakpoints.lg.max)) {
    return 'lg';
  }
  else {
    return 'xl';
  }
};
const getSm = (input) => input.sm || input.xs;
const getMd = (input) => input.md || getSm(input);
const getLg = (input) => input.lg || getMd(input);
const getXl = (input) => input.xl || getLg(input);
const getResponsiveAttributeValue = (input, screenSize, defaultValue) => {
  if (isNumber(input)) {
    return +input;
  }
  else if (typeof input === 'string') {
    return input;
  }
  else if (typeof input === 'object') {
    switch (screenSize) {
      case 'xl': {
        return getResponsiveAttributeValue(getXl(input), screenSize, defaultValue);
      }
      case 'lg': {
        return getResponsiveAttributeValue(getLg(input), screenSize, defaultValue);
      }
      case 'md': {
        return getResponsiveAttributeValue(getMd(input), screenSize, defaultValue);
      }
      case 'sm': {
        return getResponsiveAttributeValue(getSm(input), screenSize, defaultValue);
      }
      default: {
        return getResponsiveAttributeValue(input.xs, screenSize, defaultValue);
      }
    }
  }
  else {
    return (defaultValue !== null && defaultValue !== void 0 ? defaultValue : 0);
  }
};

export { getResponsiveAttributeValue as a, getScreenSize as g };
